<template>
  <div class="my-locations">
    <div class="my-locations__back" @click="changeView('Settings')">
      <i class="ion ion-chevron-back-outline"></i>
      <span>{{ $t("settings.back") }}</span>
    </div>
    <template>
      <div
        v-if="locations.length > 0"
        class="my-locations__header"
        :class="screenWidth < 800 ? 'my-locations__header--responsive' : ''"
      >
        <div class="my-locations__searcher-container">
          <input
            type="text"
            class="my-locations__searcher"
            v-model="locationsSearchText"
            :placeholder="$t('settings.myLocationsSettings.search')"
            @input="searchText()"
          />
        </div>
        <div class="my-locations__add-container" v-if="!loading">
          <button @click="openCreateLocationModal" class="my-locations__add-button">
            New Location
          </button>
        </div>
      </div>
      <b-overlay :show="loading" class="overlay-div" rounded="sm">
        <Empty v-if="!locations.length && !loading" message="No locations were found."
          ><template #content>
            <LocationsNotFound />
            <p>
              No locations found. Click
              <span @click="openCreateLocationModal"
                ><strong class="add-new-location">here</strong></span
              >
              to add a new one.
            </p>
          </template>
        </Empty>
        <template v-if="locations.length && !loading">
          <div class="my-locations__content" v-if="screenWidth >= 800 && !noLocationsFound">
            <div class="my-locations__left">
              <Locationslist
                v-if="filteredLocations"
                :locations="filteredLocations"
                :loading="loading"
                @selectedLocation="setSelectedLocation"
              />
            </div>
            <div class="my-locations__right">
              <LocationDetail
                :loadingInfo="loading"
                :location="selectedLocation"
                @deleteLocation="deleteLocation"
              />
            </div>
          </div>
          <div
            class="my-locations__content"
            v-if="screenWidth && screenWidth < 800 && !noLocationsFound"
          >
            <LocationslistResponsive
              v-if="filteredLocations"
              :locations="filteredLocations"
              @deleteLocation="deleteLocation"
              @loadingLocationsModel="loadingLocationsModel"
            />
          </div>
        </template>
        <template #overlay v-if="loading">
          <div class="loading">
            <b-icon icon="truck" font-scale="3" animation="cylon" class="loading__text"></b-icon>
            <p class="loading__text">{{ $t("loading") }}...</p>
          </div>
        </template>
      </b-overlay>
    </template>
    <b-modal
      v-model="showCreateLocationModal"
      hide-header
      hide-footer
      size="lg"
      no-close-on-backdrop
      centered
    >
      <CreateLocationModal
        @closeModal="closeCreateLocationModal"
        @locationCreated="addNewLocation"
        @validateLocations="validateLocationsLength"
      />
    </b-modal>
  </div>
</template>

<script>
import Locationslist from "./components/List.vue";
import LocationslistResponsive from "./components/ListResponsive.vue";
import LocationDetail from "./components/LocationDetail.vue";
import CreateLocationModal from "./components/CreateLocationModal.vue";
import Empty from "../../../components/Empty.vue";
import onReSize from "../../../Extend/onResize";
import LocationsNotFound from "../../../components/notFound.vue";

export default {
  name: "MyLocations",
  mixins: [onReSize],
  components: {
    Locationslist,
    LocationDetail,
    LocationslistResponsive,
    CreateLocationModal,
    LocationsNotFound,
    Empty,
  },
  data() {
    return {
      locations: [],
      account_id: process.env.VUE_APP_ACCOUNT_ID_TEST,
      skip: 0,
      limit: 200,
      allLocationsAreLoaded: false,
      selectedLocation: null,
      loading: true,
      showCreateLocationModal: false,
      locationsSearchText: "",
      filteredLocations: [],
      noLocationsFound: false,
    };
  },
  watch: {
    filteredLocations() {
      this.validateLocationsLength();
    },
  },
  async created() {
    this.locations = await this.getLocations();
    this.filteredLocations = JSON.parse(JSON.stringify(this.locations));
    this.loading = false;
  },
  methods: {
    async getLocations() {
      if (this.$options.filters.checkPermission("get:salesforce-accounts")) {
        const response = await this.$store.dispatch("load/stops/getSalesforceAccounts", {
          params: {
            skip: this.skip,
            limit: this.limit,
            account_id: this.account_id,
          },
        });
        return response;
      }
      return null;
    },
    validateLocationsLength() {
      if (this.filteredLocations.length === 0) {
        this.noLocationsFound = true;
      } else {
        this.noLocationsFound = false;
      }
    },
    openCreateLocationModal() {
      this.showCreateLocationModal = true;
    },
    setSelectedLocation(data) {
      this.selectedLocation = data;
    },
    deleteLocation(location) {
      this.locationsSearchText = "";
      this.searchText("");
      this.selectedLocation = null;
      this.filteredLocations = this.filteredLocations.filter((item) => item.id !== location.id);
      this.locations = this.locations.filter((item) => item.id !== location.id);
    },
    loadingLocationsModel(data) {
      this.loading = data;
    },
    changeView(pathName) {
      if (this.$route.name !== pathName) {
        this.$router.push({
          name: pathName,
        });
      }
    },
    closeCreateLocationModal() {
      this.showCreateLocationModal = false;
    },
    addNewLocation(data) {
      this.locations.unshift(data);
      this.filteredLocations.unshift(data);
    },
    searchText() {
      // eslint-disable-next-line implicit-arrow-linebreak
      this.filteredLocations = this.locations.filter(
        (location) =>
          // eslint-disable-next-line implicit-arrow-linebreak
          location.name.toLowerCase().includes(this.locationsSearchText.toLowerCase())
        // eslint-disable-next-line function-paren-newline
      );
      if (!this.locationsSearchText) {
        this.filteredLocations = this.locations;
      }
    },
    // async loadMoreItems() {
    //   if (!this.allLocationsAreLoaded) {
    //     this.skip += 25;
    //     const locations = await this.getLocations({
    //       skip: this.skip,
    //       limit: this.limit,
    //     });
    //     if (!locations.length) {
    //       this.allLocationsAreLoaded = true;
    //     }
    //     this.locations = this.locations.concat(locations);
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/components/Modal.scss";

.my-locations {
  @include splitted-view-styles;
  padding-top: 2%;
  padding-bottom: 20px;
  &__header {
    width: 59%;
    padding: 20px 20px 10px 15px;
    display: flex;
    margin-bottom: 1%;
    align-items: center;
  }
  &__header--responsive {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
  &__back {
    display: flex;
    align-items: center;
    margin-left: 1%;
    @include font-small-standart-text;
    font-weight: bold;
    width: 160px;
    cursor: pointer;
    i {
      font-size: 16px;
      margin-right: 5px;
    }
  }
  &__searcher {
    width: 100%;
    margin-left: 5px;
    background-color: transparent;
    border: none;
    outline: none;
    text-align: left;
    font-family: $font-family-portal;
    @include font-small-standart-text;
    letter-spacing: 0px;
    color: $color-primary-company;
  }
  &__searcher-container {
    width: 100%;
    height: 40px;
    padding: 6px;
    border-radius: 10px;
    border: 1px solid $color-border-container;
    margin-right: 15px;
  }
  &__right {
    padding-right: 4px;
    min-height: 700px;
    height: 80vh;
  }
  &__left {
    position: relative;
    padding-left: 0px;
    min-height: 700px;
    border-right: 1px solid $color-border-container;
    padding-right: 1rem;
    height: 80vh;
  }
  &__add-button {
    @include primary-button($heightSize: 40px);
    // background-color: $color-border-container;
    @extend %flex-center-center;
    padding: 5px 20px;
    width: 150px;
    border-radius: 13px;
    @include font-button-text;
    font-weight: 400;
    &:disabled {
      background-color: rgba(177, 177, 177, 0.774);
      &:hover {
        background-color: rgba(177, 177, 177, 0.774);
      }
    }
  }
}

.overlay-div {
  width: 100%;
}
.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  margin-top: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loading {
  svg,
  p {
    color: $color-primary-company;
    font-weight: bold;
  }
}
.add-new-location {
  cursor: pointer;
}

::v-deep .empty{
  margin-top: 200px;
}

::v-deep .modal-content {
  width: 100%;
}

::v-deep {
  .floatlabel-input,
  .input-container {
    border: 1px solid $color-border-container;
  }
}
</style>
