<template>
  <div class="accessorial-location-list">
    <ul class="accessorial-list">
      <li
        class="accessorial-list__item"
        v-for="(accessorialId, index) in accessorialList"
        :key="index"
      >
        {{ getAccessorialName(accessorialId) }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "AccessorialLocationList",
  props: {
    accessorialList: Array,
  },
  data() {
    return {
      accessorials: [],
    };
  },
  created() {
    this.getAccessorials();
  },
  methods: {
    getAccessorials() {
      this.accessorials = this.$store.getters["load/accessorials/getAccessorials"](-1);
    },
    getAccessorialName(accessorialId) {
      const idValue = parseInt(accessorialId, 10);
      const matchedAccessorial = this.accessorials.find(
        (accessorial) => accessorial.id === idValue
      );
      return matchedAccessorial ? matchedAccessorial.name : "";
    },
  },
};
</script>

<style lang="scss" scoped></style>
