<template>
  <div class="contacts">
    <div class="contacts-title">
      <h3 class="contacts-title__title">Shipping/Receiving Contact(s)</h3>
    </div>
    <template v-if="contacts && contacts.length">
      <div class="add-contact">
        <button class="add-contact__button" @click="openContactCreationModal">Add contact</button>
      </div>
      <div class="contact-container-scroll">
        <div class="contact-container" v-for="contact in contacts" :key="contact.id">
          <div class="contact-container__items-wrapper">
            <div class="contact-container__info">
              <div class="contact-container__item">
                <i class="ion ion-person-outline contact-container__icon"></i>
                <p class="contact-container__text">
                  {{ contact.first_name }} {{ contact.last_name }}
                </p>
              </div>
              <div class="contact-container__item">
                <i class="ion ion-mail-outline contact-container__icon"></i>
                <p class="contact-container__text">{{ contact.email }}</p>
              </div>
              <div class="contact-container__item">
                <i class="ion ion-call-outline contact-container__icon"></i>
                <p class="contact-container__text">{{ formatPhoneNumber(contact.phone) }}</p>
              </div>
            </div>
            <button
              v-if="
                $options.filters.checkPermission('delete:salesforce-contact') && contacts.length > 1
              "
              @click="deleteContact(contact)"
              title="Delete contact"
              class="contact-container__delete-button"
            >
              <i class="ion ion-trash-outline"></i>
            </button>
          </div>
          <div class="divider"></div>
        </div>
      </div>
    </template>
    <template v-else>
      <p>
        Contacts not found. Click
        <span @click="openContactCreationModal" class="add-contact__word-button">here</span>
        to add one.
      </p>
    </template>
    <b-modal
      v-model="showContactModal"
      hide-header
      hide-footer
      centered
      size="lg"
      no-close-on-backdrop
    >
      <CreateContact
        v-model="showContactModal"
        :accountId="location.id_salesforce"
        @newContact="addNewcontact"
      />
    </b-modal>
  </div>
</template>

<script>
import FormatPhoneNumber from "@/Extend/FormatPhoneNumber";
import CreateContact from "./CreateContact.vue";

export default {
  name: "Contacts",
  props: {
    location: Object,
    contacts: {
      type: Array,
      default: () => [],
    },
  },
  mixins: [FormatPhoneNumber],
  components: {
    CreateContact,
  },
  data() {
    return {
      showContactModal: false,
    };
  },
  methods: {
    addNewcontact(data) {
      this.$emit("newContact", data);
    },
    deleteContact(contact) {
      if (this.$options.filters.checkPermission("delete:salesforce-contact")) {
        const data = {
          contactInfo: contact,
          locationId: this.location.id,
        };
        this.$emit("deleteContact", data);
      }
    },
    openContactCreationModal() {
      this.showContactModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/scss/components/Modal.scss";

.contacts-title {
  margin-bottom: 10px;
  // height: 20px;
  &__title {
    @include label-input-information;
    @include font-standart-text;
    font-weight: bold;
    margin: 0px;
    margin-bottom: 10px;
    height: 20px;
  }
}

.add-contact {
  height: 60px;
  &__button {
    @include primary-button;
    width: 160px;
    //   &:hover {
    //   background-color: $color-border-container;
    //   color: $color-white;
    // }
  }
  &__word-button {
    text-decoration: underline;
    cursor: pointer;
  }
}

.contact-container-scroll {
  width: 100%;
  overflow-y: auto;
  max-height: calc(400px);
  @extend %scroll-bar-styles;
  padding-right: 1rem;
  margin-bottom: 0.8rem;
  &::-webkit-scrollbar {
    height: 7px;
  }
}

.contact-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  &__items-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  &__item {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
  }
  &__icon {
    padding-right: 5px;
    color: $color-border-container;
  }
  &__text {
    margin: 0px;
    overflow-wrap: anywhere;
  }
  &__delete-button {
    width: 10%;
    background: none;
    border: none;
    color: $color-remove-inactive-button;
    & i {
      font-size: 20px;
    }
  }
  &__info {
    width: 90%;
  }
}
.divider {
  border-bottom: 1px solid $color-border-container;
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
}
</style>
