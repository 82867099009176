<template>
  <div class="locations-list">
    <Header :fields="tableHeaderProps.fields" :styles="cellStyles" />
    <Rows
      v-if="locations.length"
      ref="locations-table"
      :data="tableContent.data"
      :dataKeys="tableContent.keys"
      :styles="cellStyles"
      :selectedItem="selectedItem"
      :pathIdParam="'locationId'"
      @selectedElement="setSelectedLocation"
    />
    <!-- <div v-if="locations.length === 0" class="locations-list__no-locations">
      <span>No locations found</span>
    </div> -->
    <div v-if="locations.length===0 && !loading"  class="locations-list__no-locations">
      <LocationsNotFound />
      <p>No locations found</p>
    </div>
  </div>
</template>

<script>
import Header from "../../../../components/TableHeaderContainer.vue";
import Rows from "../../../../components/TableRowsContainer.vue";
import LocationsNotFound from "../../../../components/notFound.vue";
/* eslint-disable implicit-arrow-linebreak */

export default {
  name: "LocationsList",
  props: {
    locations: Array,
    loading: Boolean
  },
  components: {
    Header,
    Rows,
    LocationsNotFound,
  },
  data() {
    return {
      tableHeaderProps: {
        fields: [
          { key: "location_name", label: "locationName" },
          { key: "address", label: "address" },
          { key: "city", label: "city" },
          { key: "zipcode", label: "zipcode" },
        ],
      },
      cellStyles: {
        width: "25%",
      },
      tableContent: {
        data: null,
        keys: ["name", "street", "city", "zip_code"],
      },
      selectedItem: null,
      selectedItemIndex: 0,
      dataLoaded: false,
    };
  },
  created() {
    this.generateTableData();
  },
  // mounted() {
  //   this.$nextTick(() => {
  //     this.scrollToLocation();
  //   });
  // },
  watch: {
    locations: {
      handler() {
        this.generateTableData();
        this.$nextTick(() => {
          if (!this.dataLoaded) {
            this.dataLoaded = true;
            this.findLocation();
            this.scrollToLocation();
          }
          // this.scrollToStartLocations();
        });
      },
    },
  },
  methods: {
    scrollToLocation() {
      this.$refs["locations-table"].scrollToElement();
    },
    scrollToStartLocations() {
      if (this.locations.length) {
        this.$refs["locations-table"].scrollToStartElements();
      }
    },
    generateTableData() {
      this.tableContent.data = this.locations;
    },
    findLocation() {
      const foundLocation = this.locations.find(
        (location) => location.id === parseInt(this.$route.params.locationId, 10)
      );
      if (foundLocation) {
        this.selectedItem = foundLocation;
      } else {
        [this.selectedItem] = this.locations;
      }
    },
    setSelectedLocation(data) {
      this.replaceRouterParams(data);
      this.selectedItemIndex = this.locations.findIndex((item) => item.id === data.id);
      this.$emit("selectedLocation", data);
    },
    replaceRouterParams(data) {
      this.$router
        .replace({
          params: { locationId: data.id },
        })
        .catch((err) => {
          if (err.name !== "NavigationDuplicated") throw err;
        });
    },
    // loadMoreItems() {
    //   this.$emit("loadMoreItems");
    // },
  },
};
</script>

<style lang="scss" scoped>
.locations-list {
  height: 100%;
  &__no-locations {
    color: $color-primary-company;
  }
}
</style>
