<template>
  <div class="search-input-accessorials">
    <div class="search-container">
      <i class="ion ion-search-outline"></i>
      <div class="search-container__input">
        <input
          type="text"
          class="search--input"
          v-model="searchQuery"
          placeholder="Search..."
          @input="$emit('input', searchQuery)"
        />
        <button v-show="searchQuery" @click="clearSearchFilter()" class="search-container__button">
          X
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchInputAccessorials",
  data() {
    return {
      searchQuery: "",
    };
  },
  methods: {
    clearSearchFilter() {
      this.searchQuery = "";
      this.$emit("input", "");
    },
  },
};
</script>

<style lang="scss" scoped>
.search-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 4px;
  margin: 20px auto;
  border: 1px solid $color-border-container;
  border-radius: 10px;
  background-color: white;
  i {
    font-size: 17.5px;
    color: $color-border-container;
    margin-right: 8px;
  }
  &__input {
    width: 100%;
  }
  &__button {
    position: absolute;
    background-color: transparent;
    border: none;
    &:hover {
      background-color: transparent;
    }
  }
}
.search--input {
  @include font-standart-text;
  width: 95%;
  height: 100%;
  border: none;
  color: $color-primary-company;
  &:focus {
    color: $color-primary-company;
    outline: none;
  }
}
</style>
