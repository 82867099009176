export default {
  methods: {
    denyCanadaCountryInFtlAndExpeditedLoad() {
      const canadaIndex = this.countryCodes.findIndex((code) => code.value === "CA");
      if (canadaIndex !== -1) {
        this.countryCodes.splice(canadaIndex, 1);
      } else {
        this.countryCodes.push({ label: "CA", value: "CA" });
      }
    },
  },
};
