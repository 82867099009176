<template>
  <div class="accessorial-selector-modal">
    <div class="search-container">
      <SearchInputAccessorials v-model="searchQuery" />
    </div>
    <div class="accessorials-container">
      <div class="top-line"></div>
      <div class="accessorials-group">
        <AccessorialsGroupList
          :title="$t('allOptions.general')"
          :accessorials="generalAccessorials"
          :selectedAccessorialsVmodel="selectedAccessorialsVmodel.general"
          :type="'general'"
          @checkAccessorial="checkAccessorial"
        />
      </div>
      <div class="accessorials-group">
        <AccessorialsGroupList
          :title="$t('allOptions.origin')"
          :accessorials="originAccessorials"
          :selectedAccessorialsVmodel="selectedAccessorialsVmodel.pickup"
          :type="'pickup'"
          @checkAccessorial="checkAccessorial"
        />
      </div>
      <div class="accessorials-group">
        <AccessorialsGroupList
          :title="$t('allOptions.delivery')"
          :accessorials="deliveryAccessorials"
          :selectedAccessorialsVmodel="selectedAccessorialsVmodel.delivery"
          :type="'delivery'"
          @checkAccessorial="checkAccessorial"
        />
      </div>
    </div>
    <div class="button-container">
      <button @click="saveAccessorialsInSalesforceAccount()">Save</button>
    </div>
  </div>
</template>

<script>
import SearchInputAccessorials from "../../../../components/SearchInputAccessorials.vue";
import AccessorialsGroupList from "../../../../components/AccessorialsGroupList.vue";

export default {
  name: "AccessorialSelectorModal",
  components: {
    SearchInputAccessorials,
    AccessorialsGroupList,
  },
  data() {
    return {
      searchQuery: "",
      accessorials: [],
      selectedAccessorialsVmodel: {
        general: {},
        pickup: {},
        delivery: {},
      },
      selectedAccessorials: null,
    };
  },
  props: {
    location: Object,
  },
  created() {
    this.getAccessorials();
  },
  computed: {
    generalAccessorials() {
      return this.accessorials.filter((item) => item.accessorial_type === "general");
    },
    originAccessorials() {
      return this.accessorials.filter((item) => item.accessorial_type === "pick up");
    },
    deliveryAccessorials() {
      return this.accessorials.filter((item) => item.accessorial_type === "delivery");
    },
  },
  watch: {
    searchQuery() {
      this.getAccessorials();
    },
    selectedAccessorialsVmodel: {
      deep: true,
      handler(newVal) {
        // eslint-disable-next-line no-restricted-syntax
        for (const type in newVal) {
          if (Object.hasOwnProperty.call(newVal, type)) {
            // eslint-disable-next-line no-restricted-syntax
            for (const id in newVal[type]) {
              if (Object.hasOwnProperty.call(newVal[type], id)) {
                if (newVal[type][id] === undefined) {
                  this.$set(this.selectedAccessorialsVmodel[type], id, true);
                }
              }
            }
          }
        }
      },
    },
  },
  methods: {
    clearSearchFilter() {
      this.searchQuery = "";
    },
    getAccessorials() {
      this.accessorials = this.$store.getters["load/accessorials/getAccessorials"](-1);
      this.filterSearchAccessorials();
      this.selectedAccessorials = [...this.location.accessorials];
      // Check the selected accessorials
      // eslint-disable-next-line no-restricted-syntax
      for (const type in this.selectedAccessorialsVmodel) {
        if (Object.hasOwnProperty.call(this.selectedAccessorialsVmodel, type)) {
          this.selectedAccessorialsVmodel[type] = {};
        }
      }
      this.selectedAccessorials.forEach((id) => {
        const idValue = parseInt(id, 10);
        const accessorial = this.accessorials.find((item) => item.id === idValue);
        if (accessorial) {
          let type = accessorial.accessorial_type;
          if (type === "pick up") {
            type = "pickup";
          }
          this.selectedAccessorialsVmodel[type][id] = true;
        }
      });
    },
    filterSearchAccessorials() {
      this.accessorials = this.accessorials.filter(
        (accessorial) => accessorial.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          accessorial.accessorial_type.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    selectedAccessorialsIds(selectedAccessorialsVmodel) {
      const allIds = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const type in selectedAccessorialsVmodel) {
        if (Object.hasOwnProperty.call(selectedAccessorialsVmodel, type)) {
          const typeIds = Object.keys(selectedAccessorialsVmodel[type])
            .filter((id) => selectedAccessorialsVmodel[type][id])
            .map((id) => parseInt(id, 10));
          allIds.push(...typeIds);
        }
      }
      return allIds;
    },
    saveAccessorialsInSalesforceAccount() {
      this.$emit("handlingLoading", true);
      const formattSelectedAccessorials = this.buildingSelectedAccessorialsData();
      const newAccessorials = this.selectedAccessorialsIds(formattSelectedAccessorials);

      if (JSON.stringify(newAccessorials) === JSON.stringify(this.location.accessorials)) {
        this.$emit("handlingLoading", false);
        this.$emit("handleAccessorialModal", false);
        this.$emit("resetAccessorialList");
        return;
      }

      const body = this.location;
      body.accessorials = newAccessorials;
      const payload = {
        id: this.location.id,
        body,
      };
      this.$store.dispatch("load/stops/updateSalesforceAccount", payload);
      this.$emit("handlingLoading", false);
      this.$emit("handleAccessorialModal", false);
      this.$emit("resetAccessorialList");
    },
    checkAccessorial({ accessorial, type, value }) {
      if (value) {
        this.selectedAccessorials.push(accessorial.id);
      } else {
        const index = this.selectedAccessorials.indexOf(accessorial.id);
        if (index !== -1) {
          this.selectedAccessorials.splice(index, 1);
        }
      }
      this.selectedAccessorialsVmodel[type][accessorial.id] = value;
    },
    buildingSelectedAccessorialsData() {
      const organizedAccessorialsVmodel = {
        general: {},
        pickup: {},
        delivery: {},
      };
      const accessorials = this.$store.getters["load/accessorials/getAccessorials"](-1);
      accessorials.forEach((accessorial) => {
        if (this.selectedAccessorials.includes(accessorial.id)) {
          if (accessorial.accessorial_type) {
            if (!organizedAccessorialsVmodel[accessorial.accessorial_type]) {
              organizedAccessorialsVmodel[accessorial.accessorial_type] = {};
            }
            organizedAccessorialsVmodel[accessorial.accessorial_type][accessorial.id] = true;
          }
        }
      });
      return organizedAccessorialsVmodel;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/scss/components/Checkboxes.scss";

.accessorial-selector-modal {
  position: relative;
  background-color: $color-gray-light;
  border-radius: 10px;
  color: $color-primary-company;
  font-family: $font-family-portal;
}
.accessorials-container {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.top-line {
  position: absolute;
  width: 100%;
  top: 14.5%;
  border-top: 1.5px solid $color-border-container;
}
.accessorials-group {
  min-height: 188px;
  &:not(:last-child) {
    border-right: 1.5px solid $color-border-container;
  }
  &__title-container {
    text-align: center;
    margin-bottom: 15px;
    @include font-standart-text;
  }
  &__checkboxes {
    padding: 10% 0px;
    padding-top: 5%;
    max-height: 200px;
    overflow: scroll;
    overflow-x: hidden;
    text-align: center;
    &:not(:last-child) {
      border-right: 1.5px solid $color-border-container;
      padding-right: 10px;
    }
  }
  &__checkbox {
    display: flex;
    justify-content: flex-start;
    padding: 2% 0px;
    padding-left: 10%;
  }
  &__text {
    @include font-standart-text;
    margin-left: 10px;
    text-align: left;
  }
  &__title {
    font-weight: bold;
  }
}
.button-container {
  width: 40%;
  margin: auto;
  margin-top: 20px;
  button {
    @include primary-button($heightSize: 40px);
    width: 100%;
    border-radius: 10px;
    @include font-button-text;
    font-weight: 400;
  }
}

// CSS for the scroll
/* width */
::-webkit-scrollbar {
  width: 3px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: $color-border-container;
  border-radius: 8px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0097a8;
}
</style>
