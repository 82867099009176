<template>
  <div class="locations-list-responsive accordion" ref="locations-table">
    <div
      class="locations-list-responsive__card"
      v-for="location in newLocations"
      :key="location.id"
    >
      <div
        class="locations-list-responsive__header-info"
        v-b-toggle="'collapse' + location.id"
      >
        <div class="locations-list-responsive__header-info__text">
          <span class="locations-list-responsive__header-info__name">
            {{ location.name }}
          </span>
          <span class="locations-list-responsive__header-info__address">
            {{ location.street }}, {{ location.city }},
            {{ location.state_code }},
            {{ location.zip_code }}
          </span>
        </div>
        <div class="header-icon">
          <i class="ion ion-chevron-forward-outline"></i>
        </div>
      </div>
      <b-collapse
        class="accordion"
        accordion="my-accordion"
        role="tabpanel"
        :id="'collapse' + location.id"
        @show="showCard(location)"
        @hide="hideCard(location)"
      >
        <div class="accordion__divider"></div>
        <div class="accordion__info">
          <LocationDetail
            v-if="location.collapsed"
            :location="location"
            @deleteLocation="deleteLocation"
          />
        </div>
      </b-collapse>
    </div>
    <div
      v-if="locations.length === 0"
      class="locations-list-responsive__no-locations"
    >
    <span>No locations found</span>
    </div>
  </div>
</template>

<script>
/* eslint-disable implicit-arrow-linebreak */
import LocationDetail from "./LocationDetail.vue";

export default {
  name: "LocationsListResponsive",
  props: {
    locations: Array,
  },
  components: {
    LocationDetail,
  },
  data() {
    return {
      selectedItem: null,
      selectedItemIndex: 0,
      newLocations: [],
    };
  },
  created() {
    this.buildCustomData(this.locations);
  },
  watch: {
    locations() {
      this.buildCustomData(this.locations);
    },
  },
  methods: {
    deleteLocation(data) {
      this.$emit("deleteLocation", data);
    },
    buildCustomData(data) {
      this.$emit("loadingLocationsModel", true);
      this.newLocations = data.map((item) => ({
        ...item,
        collapsed: false,
      }));
      this.$emit("loadingLocationsModel", false);
    },
    async showCard(item) {
      item.collapsed = true;
    },
    async hideCard(item) {
      item.collapsed = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.locations-list-responsive {
  width: 100%;
  &__card {
    width: 100%;
    @include collapse-container-styles;
    text-align: left;
    color: $color-primary-company;
  }
  &__header-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &__text {
      display: flex;
      flex-direction: column;
    }
    &__name {
      @include font-section-title;
      font-weight: bold;
      text-transform: capitalize;
    }
    &__address {
      @include font-standart-text;
      font-weight: normal;
    }
  }
  &__no-locations {
    color: $color-primary-company;
    font-weight: bold;
    margin-top: 50px;
  }
}
.accordion {
  &__divider {
    border-bottom: 1px solid $color-border-container;
    margin: 15px 0 25px 0;
  }
  &__info {
    display: flex;
    flex-direction: column;
  }
  &__contact-not-found {
    text-decoration: underline;
    cursor: pointer;
  }
}
.header-icon {
  font-size: 25px;
}
</style>
