<template>
  <div class="empty">
    <slot name="content">
      <i class="ion ion-file-tray-outline empty__icon"></i>
      <p>{{ message || "No elements ere found." }}</p>
    </slot>
  </div>
</template>

<script>
export default {
  name: "Empty",
  props: {
    message: String,
  },
};
</script>

<style lang="scss" scoped>
.empty {
  &__icon {
    font-size: 50px;
  }
}
</style>
