<template>
  <div class="badge">
    <span class="badge__number">{{ quantity }}</span>
  </div>
</template>

<script>
export default {
  name: "Badge",
  props: {
    quantity: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped>
.badge {
  min-width: 20px;
  position: absolute;
  background-color: white;
  border-radius: 50px;
  border: 1px solid $color-border-container;
  color: $color-border-container;
  top: -8px;
  right: -5px;
}
</style>
