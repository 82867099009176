<template>
  <div>
    <div v-if="location" class="location-detail">
      <b-overlay :show="loading" rounded="sm">
        <div class="information-container">
          <section class="location-detail__title-container" v-if="screenWidth >= 800">
            <h2 class="location-detail__title">
              {{ capitalizedLocationName }}
            </h2>
          </section>
          <section class="item-container">
            <div class="item-container__item">
              <i class="ion ion-location-outline item-container__icon"></i>
              <p class="item-container__text">{{ fullAddress }}</p>
            </div>
            <!-- <div class="item-container__item">
              <i class="ion ion-locate-outline item-container__icon"></i>
              <p>{{ location.street }} - {{ location.zip_code }}</p>
            </div> -->
          </section>
          <Contacts
            :contacts="receivingContacts"
            :location="location"
            @newContact="addNewcontact"
            @deleteContact="deleteContact"
          />
          <strong class="accesorial-title">Default Location Accessorials</strong>
          <i
            class="ion ion-information-circle-outline"
            style="margin-left: 5px"
            v-b-popover.hover.top="accessorialsMessagePopover"
          ></i>
          <div class="button-container">
            <Badge
              class="number-counter"
              :quantity="location.accessorials.length"
              v-if="location.accessorials.length > 0"
            />
            <button class="add-accessorial__button" @click="handleAccessorialModal(true)">
              Add Accessorial
            </button>
          </div>
          <section class="accessorial-list-container">
            <AccessorialLocationList :key="key" :accessorialList="location.accessorials" />
          </section>
          <CustomModal
            :centered="true"
            :no-close-on-backdrop="true"
            size="lg"
            :showOverlay="loadingAccessorials"
            v-model="showAccessorialModal"
          >
            <template #modal-header>
              <h3 class="accesorial-modal__title">
                Add Accessorials
                <i
                  class="ion ion-information-circle-outline"
                  style="margin-left: 5px"
                  v-b-popover.hover.top="accessorialsMessagePopover"
                ></i>
              </h3>
            </template>
            <template #modal-content>
              <AccessorialSelectorModal
                :location="location"
                @handleAccessorialModal="handleAccessorialModal"
                @handlingLoading="handlingLoading"
                @resetAccessorialList="resetAccessorialList"
              />
            </template>
          </CustomModal>
        </div>
        <div
          class="delete-location"
          v-if="$options.filters.checkPermission('delete:salesforce-account')"
        >
          <button @click="deleteLocation" class="delete-location__button">Delete location</button>
        </div>
      </b-overlay>
    </div>
    <div v-if="!location && !loadingInfo" class="choose-location">
      <ChooseLocation />
      <p>Choose a location</p>
    </div>
  </div>
</template>

<script>
/* eslint-disable operator-linebreak */
/* eslint-disable arrow-body-style */
/* eslint-disable max-len */
import Badge from "@/components/Badge.vue";
import Contacts from "./Contacts.vue";
import onReSize from "../../../../Extend/onResize";
import ChooseLocation from "../../../../components/chooseLocation.vue";
import AccessorialLocationList from "./AccessorialLocationList.vue";
import AccessorialSelectorModal from "./AccessorialSelectorModal.vue";
import CustomModal from "../../../../components/CustomModal.vue";

export default {
  name: "LocationDetail",
  mixins: [onReSize],
  props: {
    location: Object,
    loadingInfo: Boolean,
  },
  components: {
    Contacts,
    ChooseLocation,
    AccessorialLocationList,
    AccessorialSelectorModal,
    CustomModal,
    Badge,
  },
  data() {
    return {
      receivingContacts: null,
      loading: false,
      showAccessorialModal: false,
      loadingAccessorials: false,
      key: 0,
      accessorialsMessagePopover:
        "If you plan to use this location for origin and delivery, please select pickup and delivery accessorials as default. When quoting a load, the accessorials will only add the fee to the matching location.",
    };
  },
  async created() {
    await this.$store.dispatch("load/accessorials/getAllAccessorials", -1);
  },
  computed: {
    capitalizedLocationName() {
      return this.location.name.charAt(0).toUpperCase() + this.location.name.slice(1);
    },
    fullAddress() {
      return `${this.location.street.toUpperCase()} - ${this.location.city}, ${
        this.location.state_code
      } (${this.location.zip_code}), ${this.location.country_code}`;
    },
  },
  watch: {
    location: {
      immediate: true,
      async handler() {
        this.loading = true;
        if (this.location) {
          const contactsVuex = this.$store.getters["load/stops/getSalesforceContacts"];
          // Check if it already has contacts in the vuex
          if (contactsVuex && contactsVuex[this.location.id]) {
            this.receivingContacts = contactsVuex[this.location.id];
          } else {
            this.receivingContacts = await this.getReceivingContacts();
            const newContacts = {
              ...contactsVuex,
              [this.location.id]: this.receivingContacts,
            };
            this.$store.commit("load/stops/setSalesforceContacts", newContacts);
          }
        }
        this.loading = false;
      },
    },
  },
  methods: {
    getReceivingContacts() {
      if (this.$options.filters.checkPermission("get:salesforce-contacts")) {
        this.$store.commit("triggerAbortController");
        return this.$store.dispatch("load/stops/getSalesforceContactsByAccount", {
          params: {
            account_id: this.location.id_salesforce,
          },
        });
      }
      return null;
    },
    addNewcontact(data) {
      this.receivingContacts.push(data);
      const contactsVuex = this.$store.getters["load/stops/getSalesforceContacts"];
      const newContacts = {
        ...contactsVuex,
        [this.location.id]: this.receivingContacts,
      };
      this.$store.commit("load/stops/setSalesforceContacts", newContacts);
    },
    async deleteContact(data) {
      if (this.$options.filters.checkPermission("delete:salesforce-contact")) {
        this.loading = true;
        // Delete contact from backend
        await this.$store.dispatch("load/stops/deleteSalesforceContact", data.contactInfo.id);
        this.receivingContacts = this.receivingContacts.filter(
          (item) => item.id !== data.contactInfo.id
        );
        // Delete contact from vuex
        const contactsVuex = this.$store.getters["load/stops/getSalesforceContacts"];
        const indexOfContact = contactsVuex[data.locationId].findIndex((contact) => {
          return contact.id === data.contactInfo.id;
        });
        contactsVuex[data.locationId].splice(indexOfContact, 1);
        this.$store.commit("load/stops/setSalesforceContacts", contactsVuex);
        this.loading = false;
      }
    },
    async deleteLocation() {
      if (this.$options.filters.checkPermission("delete:salesforce-account")) {
        this.swal({
          title: "Are you sure?",
          text: "This location and related contacts will be deleted.",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then(async (willDelete) => {
          if (willDelete) {
            this.loading = true;
            this.$store.dispatch("load/stops/deleteSalesforceAccount", this.location.id);
            this.$emit("deleteLocation", this.location);
            this.loading = false;
          }
        });
      }
    },
    handleAccessorialModal(data) {
      this.showAccessorialModal = data;
    },
    handlingLoading(data) {
      this.loadingAccessorials = data;
    },
    resetAccessorialList() {
      this.key += 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.location-detail {
  text-align: left;
  &__title-container {
    margin-bottom: 20px;
  }
  &__title {
    @include font-section-title;
    font-weight: bold;
    color: $color-primary-company;
  }
}

.b-overlay-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.item-container {
  margin-bottom: 20px;
  &__item {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    margin-bottom: 10px;
  }
  &__title {
    @include label-input-information;
    @include font-standart-text;
    font-weight: bold;
    margin: 0px;
  }
  &__icon {
    padding-right: 5px;
    color: $color-border-container;
  }
  &__text {
    margin: 0px;
  }
}

.delete-location {
  display: flex;
  justify-content: center;
  &__button {
    @include delete-button;
    width: 80%;
    height: 40px;
  }
}
.button-container {
  position: relative;
  width: 373px;
}
.number-counter {
  position: absolute;
  top: 10px;
  right: 156px;
}
.add-accessorial__button {
  margin: 15px auto !important;
  @include primary-button($heightSize: 40px);
  width: 40%;
  min-width: 218px;
  border-radius: 13px;
  @include font-button-text;
  font-weight: 400;
}

.accesorial-modal__title {
  @include modals-title;
  font-size: 1.5rem;
  font-weight: bold;
  font-family: $font-family-portal;
  text-align: center;
  color: $color-primary-title;
  @media (width <= 600px) {
    font-size: 1.4rem;
  }
}

.choose-location {
  width: 100%;
  text-align: center;
}

::v-deep {
  .bg-light {
    background-color: $color-white !important;
    @media (max-width: 800px) {
      background-color: $color-gray-light !important;
    }
  }
}
</style>
