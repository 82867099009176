<template>
  <div class="create-location-modal">
    <b-overlay :show="loading" rounded="sm">
      <div class="create-location-modal__header">
        <div class="create-location-modal__title">
          <h3>
            {{ $t("stopsInfo.createStopInfo.stopDetails") }}
          </h3>
        </div>
        <div class="create-location__button">
          <button @click="closeModal" class="close-button">
            <i class="ion ion-close-circle-outline close-button__icon"></i>
          </button>
        </div>
      </div>
      <hr />
      <span class="create-location-modal__subtitle">
        {{ $t("stopsInfo.createStopInfo.accountInformation") }}</span
      >
      <CreateAccountInputs :newAccountInfo="newAccountInfo" ref="account" />
      <button class="create-location-modal__button" @click="saveData()">
        {{ $t("stopsInfo.createStopInfo.save") }}
      </button>
    </b-overlay>
  </div>
</template>

<script>
import CreateAccountInputs from "../../../QuoteBook/LoadCreation/stops/CreateAccountInputs.vue";

export default {
  name: "CreateLocationModal",
  components: {
    CreateAccountInputs,
  },
  data() {
    return {
      fieldsMissingAccount: false,
      fieldsMissingContact: false,
      newAccountInfo: {
        name: "",
        country_code: "US",
        city: "",
        state_code: "",
        zip_code: "",
        street: "",
        createLocationInBd: true,
      },
      loading: false,
    };
  },
  methods: {
    async saveData() {
      const accountValidation = await this.$refs.account.validateFormfields();
      if (!accountValidation) {
        this.swal({
          title: `${this.$t("stopsInfo.createStopInfo.fieldsMissing")}`,
          text: "Please, try again.",
          icon: "error",
          dangerMode: true,
        });
      } else {
        this.loading = true;
        // Data for salesforce account
        const dataAccount = {
          name: this.newAccountInfo.name,
          street: this.newAccountInfo.street,
          city: this.newAccountInfo.city,
          zip_code: this.newAccountInfo.zip_code,
          country_code: this.newAccountInfo.country_code,
          state_code: this.newAccountInfo.state_code,
          createLocationInBd: this.newAccountInfo.createLocationInBd,
          //   // ONLY FOR TEST ACCOUNT
          account_id: process.env.VUE_APP_ACCOUNT_ID_TEST_2,
          // account_id: this.$store.getters["login/getTokenInfo"].accounts[0],
        };
        const responseCreateAccount = await this.$store.dispatch(
          "load/stops/createSalesforceAccounts",
          dataAccount
        );
        // this.$store.commit("load/stops/setNewSalesforceAccount", responseCreateAccount);
        this.$emit("locationCreated", responseCreateAccount);
        this.$emit("validateLocations");
        this.closeModal();
        this.loading = false;
      }
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style lang="scss" scoped>
.close-button {
  @include modals-close-button;
}
.create-location-modal {
  position: relative;
  background-color: $color-gray-light;
  border-radius: 10px;
  font-family: $font-family-portal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &__header {
    @include modals-header;
  }
  &__title {
    @include modals-title;
  }
  &__subtitle {
    color: $color-border-container;
    font-weight: 600;
    text-align: left;
    @include font-standart-text;
    margin: 2%;
  }
  &__button {
    @include primary-button($heightSize: 40px);
    background-color: $color-save-button;
    @extend %flex-center-center;
    padding: 5px 20px;
    margin-top: 5%;
    width: 40%;
    border-radius: 13px;
    @include font-button-text;
    font-weight: 400;
    &:hover {
      background: $color-primary-trigger-button-hover;
    }
    &:disabled {
      background-color: rgba(177, 177, 177, 0.774);
      &:hover {
        background-color: rgba(177, 177, 177, 0.774);
      }
    }
  }
}

hr {
  width: 100%;
  margin: 0 auto 1rem auto;
}

::v-deep .spinner-border {
  color: $color-primary-company;
}

::v-deep .bg-light {
  background-color: $color-gray-light !important;
}
</style>
