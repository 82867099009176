<template>
  <div class="create-contact">
    <b-overlay :show="loading" rounded="sm">
      <div class="create-contact__header">
        <div class="create-contact__title">
          <h3>Add a new contact</h3>
        </div>
        <div class="create-contact__button">
          <button @click="closeModal" class="close-button">
            <i class="ion ion-close-circle-outline close-button__icon"></i>
          </button>
        </div>
      </div>
      <hr />
      <ContactForm v-model="contactData" ref="contact-data" />
      <div class="save-contact">
        <button @click="getContactData" class="save-contact__button">Save</button>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import ContactForm from "../../../QuoteBook/shared/AccountCreation/ContactForm.vue";

export default {
  name: "CreateContact",
  props: {
    accountId: String,
  },
  components: {
    ContactForm,
  },
  data() {
    return {
      contactData: null,
      loading: false,
    };
  },
  methods: {
    async getContactData() {
      const fieldsContact = this.$refs["contact-data"].validateFormfields();
      if (fieldsContact) {
        this.loading = true;
        const response = await this.createContact(this.contactData);
        this.$emit("newContact", response);
        this.closeModal();
      }
    },
    async createContact(contactData) {
      const response = await this.$store.dispatch("load/stops/createSalesforceContacts", {
        ...contactData,
        account_id: this.accountId,
      });
      return response;
    },
    closeModal() {
      this.$emit("input", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.close-button {
  @include modals-close-button;
}

.create-contact {
  position: relative;
  font-family: $font-family-portal;
  &__header {
    @include modals-header;
  }
  &__title {
    @include modals-title;
  }
}
hr {
  margin: 0 auto 1rem auto;
}
.save-contact {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 15px;
  &__button {
    @include primary-button($heightSize: 40px);
    background-color: $color-save-button;
    @extend %flex-center-center;
    padding: 5px 20px;
    width: 40%;
    border-radius: 13px;
    @include font-button-text;
    font-weight: 400;
    &:hover {
      background-color: $color-save-button;
    }
    &:disabled {
      background-color: rgba(177, 177, 177, 0.774);
      &:hover {
        background-color: rgba(177, 177, 177, 0.774);
      }
    }
  }
}

::v-deep .bg-light {
  background-color: $color-gray-light !important;
}
::v-deep .spinner-border {
  color: $color-primary-company;
}

::v-deep {
  .floatlabel-input {
    border: 1px solid $color-border-container;
  }
}
::v-deep {
  .filters-container__select {
    width: 140px;
  }
}
</style>
