<template>
  <div class="accessorial-group-list">
    <div class="accessorials-group">
      <div class="accessorials-group__title-container">
        <span class="accessorials-group__title">{{ title }}</span>
      </div>
      <div class="accessorials-group__checkboxes">
        <div
          v-for="(item, index) in accessorials"
          :key="index"
          class="accessorials-group__checkbox"
        >
          <div class="checkbox-container" v-if="shouldShowAccessorial(item)">
            <b-form-checkbox
              v-model="selectedAccessorialsVmodel[item.id]"
              @change="handleChange(item, $event)"
              :id="item.id.toString()"
            />
            <label :for="item.id.toString()" class="accessorials-group__text">{{
              item.name
            }}</label>
          </div>
        </div>
        <p v-show="accessorials.length === 0">{{ $t("noAccessorials") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccessorialGroupList",
  props: {
    title: String,
    accessorials: Array,
    selectedAccessorialsVmodel: Object,
    type: String,
    quoteAccessorialList: Boolean
  },
  methods: {
    handleChange(accessorial, value) {
      this.$emit("checkAccessorial", { accessorial, type: this.type, value });
    },
    shouldShowAccessorial(item) {
      if (this.quoteAccessorialList) {
        return !item.most_common;
      }
      return true;
    }
  },
};
</script>

<style lang="scss" scoped>
.accessorials-group {
  min-height: 188px;
  &:not(:last-child) {
    border-right: 1.5px solid $color-border-container;
  }
  &__title-container {
    text-align: center;
    margin-bottom: 15px;
    @include font-standart-text;
  }
  &__checkboxes {
    padding: 10% 0px;
    padding-top: 5%;
    max-height: 200px;
    overflow: scroll;
    overflow-x: hidden;
    text-align: center;
    &:not(:last-child) {
      border-right: 1.5px solid $color-border-container;
      padding-right: 10px;
    }
  }
  &__checkbox {
    display: flex;
    justify-content: flex-start;
    padding: 2% 0px;
    padding-left: 10%;
  }
  &__text {
    @include font-standart-text;
    margin-left: 10px;
    text-align: left;
  }
  &__title {
    font-weight: bold;
  }
}
.checkbox-container {
  display: flex;
}
::-webkit-scrollbar {
  width: 3px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: $color-border-container;
  border-radius: 8px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0097a8;
}
</style>
