import * as yup from "yup";

const LocationSchema = yup.object().shape({
  name: yup.string().required(),
  street: yup.string().required(),
  city: yup.string().required(),
  zip_code: yup.string().required(),
});

export default LocationSchema;
