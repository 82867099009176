<template>
  <div class="custom-modal" v-if="modalVisibility">
    <b-modal
      v-model="modalVisibility"
      hide-header
      hide-footer
      :no-close-on-backdrop="!closeOnBackDrop"
      :no-close-on-esc="!closeOnEsc"
      :size="size"
      :centered="centered"
      :static="staticModal"
      @hide="closeModal"
      ref="customModal"
    >
      <div class="custom-modal" v-loading="showOverlay">
        <div class="custom-modal-header">
          <slot name="modal-header">
            <div class="custom-modal-header__header">
              <div class="custom-modal-header__title">
                <h3>{{ modalTitle }}</h3>
              </div>
              <div class="custom-modal-header__button">
                <button @click="closeModal" class="close-button d-flex flex-row-reverse">
                  <i class="ion ion-close-circle-outline close-button__icon"></i>
                </button>
              </div>
            </div>
            <hr />
          </slot>
        </div>
        <div class="custom-modal-content">
          <slot name="modal-content"></slot>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "CustomModal",
  props: {
    value: Boolean,
    size: {
      type: String,
      default: "md",
    },
    centered: {
      type: Boolean,
      default: false,
    },
    staticModal: {
      type: Boolean,
      default: false,
    },
    modalTitle: {
      type: String,
      default: "Modal Title",
    },
    showOverlay: {
      type: Boolean,
      default: false,
    },
    closeOnBackDrop: {
      type: Boolean,
      default: true,
    },
    closeOnEsc: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      modalVisibility: this.value,
    };
  },
  watch: {
    value() {
      this.modalVisibility = this.value;
    },
  },
  async mounted() {
    // modalComponent.style.width = "90%";
  },
  methods: {
    closeModal() {
      this.$emit("input", false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/components/Modal.scss";

.custom-modal-header {
  .close-button {
    @include modals-close-button;
    padding-right: 0px;
  }
  hr {
    margin-top: 0px;
    margin-bottom: 7px;
  }
  &__header {
    @include modals-header;
  }
  &__title {
    @include modals-title;
    h3 {
      font-size: 1.5rem;
      font-weight: bold;
      @media (width <= 600px) {
        font-size: 1.4rem;
      }
    }
  }
}

::v-deep {
  .el-loading-mask {
    background-color: rgba(239, 239, 239, 0.9);
  }
}
</style>
